import IndexPage from 'components/pages/index/IndexPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title="Płynność finansowa dla firm"
			keywords="finansowanie dla firm, finansowanie dostawców, tanie finansowanie, łańcuch dostaw, bez ryzyka,płynność finansowa, wypłać faktury"
			description="Odkryj potężne możliwości FINTEQ. Zdobądź finansowanie dla swojej firmy, wypłacaj faktury przed terminem albo uruchom własny program finansowania."
			lang="pl-PL"
			locale="pl_PL"
		/>

		<IndexPage/>
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
